<style lang="scss">
.themenschwerpunkte {
  [class*='col-'] {
  }
  .themen-box {
    margin: 50px 0;
    .col-lg-3 {
      margin-bottom: 30px;
      .bild-box {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 300px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: rgba($color: #000000, $alpha: 0.5);
          -webkit-transition: all 500ms ease;
          -moz-transition: all 500ms ease;
          -ms-transition: all 500ms ease;
          -o-transition: all 500ms ease;
          transition: all 500ms ease;
        }
        .txt {
          width: 100%;
          position: absolute;
          text-align: center;
          left: 0;
          top: 50%;
          z-index: 2;
          color: #fff;
          font-size: 150%;
          height: 40px;
          margin-top: -20px;
        }
        .stretched-link {
          z-index: 10000;
        }
        &:hover {
          &:after {
            background-color: rgba($color: #000000, $alpha: 0);
          }
          .txt {
            opacity: 0;
          }
        }
      }
    }
  }

  .empfehlungen {
    margin-top: 40px;
    margin-bottom: 40px;

    padding: 30px 20px;

    .intro {
      text-align: center;
      h2 {
        margin-bottom: 0px;
        margin-top: 0 !important;
        padding-top: 0;

        border-left: 0;
        &:after {
          margin: 10px auto !important;
        }
      }
      p {
        margin-bottom: 30px;
      }
    }
    .col-lg-2 {
      justify-content: center;
      align-self: flex-start;
      position: relative;

      .icon-box {
        display: block;
        width: 100%;
        img {
          max-width: 100px;
          display: block;
          margin: 0 auto;
        }
      }
    }

    .col-lg-6 {
      h3 {
        margin-bottom: 15px;
      }
      span {
        display: inline-block;
        padding: 15px 25px;
        background: #eee;
        font-size: 130%;
        color: $blue-darker;
      }
      .txt {
        padding: 15px;
        border-left: 3px solid #eee;
      }
    }
  }
  .text-center {
    text-align: center;

    display: block;
  }

  .no-inputs {
    text-align: center;
    font-family: $KleineTitel-Regular;
    padding: 60px 20px;
    font-size: 140%;
  }
}
</style>

<template>
  <div class="content themenschwerpunkte container">
    <h1>Themenschwerpunkte</h1>
    <p class="intro-txt"><strong>Sie suchen nach dem passenden Themenumfeld für Ihre Werbebotschaft, um diese zielgruppengenau auszuspielen? Kein Problem!</strong></p>
    <p>Themenschwerpunkte der Kleinen Zeitung widmen sich unterschiedlichsten Bereichen und orientieren sich stark an den Interessen und Bedürfnissen unserer User und Leser – ob als abwechslungsreiche Beilage bzw. Bestandteil der Zeitung und/oder als interessanter Content auf kleinezeitung.at.</p>
  </div>
  <!-- KALENDER-->
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2>Themenschwerpunkt-Kalender</h2>
      </div>
    </div>
  </div>
  <ThemenschwerpunktKalenderComponent />

  <!-- KALENDER ENDE-->
  <div class="bg-blue-light margin-b-m">
    <div class="wrapper-xxl outer-slider">
      <!-- -->
      <div class="print-overview-slider">
        <!-- <Slider :delay="4000" :items="{ 768: 2, 1024: 3, 1400: 3, 1600: 3 }" easing="linear" :speed="500" autoplay noclone>-->
        <Slider :delay="4000" :items="{ 768: 2, 1024: 2, 1400: 3, 1600: 3 }" easing="linear" :speed="500" noclone>
          <template #navigation="{ left, right }">
            <div class="slider-navi-outer">
              <div @click="right"><span class="material-icons arrow-btn arrow-left">arrow_back_ios</span></div>
              <div @click="left"><span class="material-icons arrow-btn arrow-right">arrow_forward_ios</span></div>
            </div>
          </template>

          <template #items>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/branchenloesungen/bsp-kostprobe.jpg" alt="Titel Exklusiv" class="img-fluid" />
                </div>
                <div class="kat">Lifestyle</div>
                <ModalButton link="modal-lifestyle" class="stretched-link"></ModalButton>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/branchenloesungen/bsp-kompass-lehre.jpg" alt="Titel Exklusiv" class="img-fluid" />
                </div>
                <div class="kat">Bildung &amp; Karriere</div>
                <ModalButton link="modal-bildung-karriere" class="stretched-link"></ModalButton>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/branchenloesungen/bsp-gesundheit-v2.jpg" alt="Titel Exklusiv" class="img-fluid" />
                </div>
                <div class="kat">Gesundheit &amp; Leben</div>
                <ModalButton link="modal-gesundheit-leben" class="stretched-link"></ModalButton>
              </div>
            </SliderItem>

            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/branchenloesungen/bsp-moblitaet.jpg" alt="Titel Exklusiv" class="img-fluid" />
                </div>
                <div class="kat">Mobilität</div>
                <ModalButton link="modal-mobilitaet" class="stretched-link"></ModalButton>
              </div>
            </SliderItem>

            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/branchenloesungen/bsp-kultursommer.jpg" alt="Titel Exklusiv" class="img-fluid" />
                </div>
                <div class="kat">Kultur</div>
                <ModalButton link="modal-kultur" class="stretched-link"></ModalButton>
              </div>
            </SliderItem>

            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/branchenloesungen/bsp-reisezeit.jpg" alt="Titel Exklusiv" class="img-fluid" />
                </div>
                <div class="kat">Tourismus &amp; Reisen</div>
                <ModalButton link="modal-tourismus-reisen" class="stretched-link"></ModalButton>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/branchenloesungen/bsp-primus-ktn.jpg" alt="Titel Exklusiv" class="img-fluid" />
                </div>
                <div class="kat">Wirtschaft und Spezialthemen</div>
                <ModalButton link="modal-wirtschaft" class="stretched-link"></ModalButton>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="inner-box">
                <div class="img-box">
                  <img src="@/assets/img/img-content/branchenloesungen/bsp-bauen-wohnen.jpg" alt="Titel Exklusiv" class="img-fluid" />
                </div>
                <div class="kat">Bauen &amp; Wohnen</div>
                <ModalButton link="modal-bauen-wohnen" class="stretched-link"></ModalButton>
              </div>
            </SliderItem>
          </template>
        </Slider>
      </div>
      <!-- -->
    </div>
  </div>
  <div class="themenschwerpunkte content container">
    <div class="themen-box" style="display:none;">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-bauen.jpg')})` }">
            <div class="txt">Bauen &amp; Wohnen</div>
            <ModalButton link="modal-bauen-wohnen" class="stretched-link"></ModalButton>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-bildung.jpg')})` }">
            <div class="txt">Bildung &amp; Karriere</div>
            <ModalButton link="modal-bildung-karriere" class="stretched-link"></ModalButton>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-gesundheit.jpg')})` }">
            <div class="txt">Gesundheit &amp; Leben</div>
            <ModalButton link="modal-gesundheit-leben" class="stretched-link"></ModalButton>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-kultur.jpg')})` }">
            <div class="txt">Kultur</div>
            <ModalButton link="modal-kultur" class="stretched-link"></ModalButton>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-lifestyle.jpg')})` }">
            <div class="txt">Lifestyle</div>
            <ModalButton link="modal-lifestyle" class="stretched-link"></ModalButton>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-mobilitaet.jpg')})` }">
            <div class="txt">Mobilität</div>
            <ModalButton link="modal-mobilitaet" class="stretched-link"></ModalButton>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-tourismus.jpg')})` }">
            <div class="txt">Tourismus &amp; Reisen</div>
            <ModalButton link="modal-tourismus-reisen" class="stretched-link"></ModalButton>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/sonderprodukte/themenschwerpunkt-wirtschaft.jpg')})` }">
            <div class="txt">Wirtschaft und Spezialthemen</div>
            <ModalButton link="modal-wirtschaft" class="stretched-link"></ModalButton>
          </div>
        </div>
      </div>
    </div>
    <Modal link="modal-bauen-wohnen" title="Bauen &amp; Wohnen">
      <h2>Unsere Empfehlungen</h2>
      <ul class="content-ul">
        <li>Wohnen-Journal</li>
        <li>Häuslbauer</li>
        <li>Wohnbauförderungsjournal</li>
        <li>Häuslbauermesse Klagenfurt</li>
        <li>Bauratgeber</li>
        <li>Magazin Garten</li>
        <li>pro:Holz</li>
      </ul>
    </Modal>
    <Modal link="modal-bildung-karriere" title="Bildung &amp; Karriere">
      <h2>Unsere Empfehlungen</h2>
      <ul class="content-ul">
        <li>Bildung Aktuell</li>
        <li>FH Guide</li>
        <li>Fachkräfte</li>
        <li>Wissen was geht.</li>
        <li>Kompass Lehre</li>
        <li>Aus und Weiterbildung</li>
        <li>Top-Jobs im Süden Österreichs</li>
      </ul>
    </Modal>
    <Modal link="modal-gesundheit-leben" title="Gesundheit &amp; Leben">
      <h2>Unsere Empfehlungen</h2>
      <ul class="content-ul">
        <li>Lebenslust</li>
        <li>Pflege</li>
        <li>Ärzteführer</li>
        <li>Themenmagazin Gesundheit</li>
      </ul>
    </Modal>
    <Modal link="modal-kultur" title="Kultur">
      <h2>Unsere Empfehlungen</h2>
      <ul class="content-ul">
        <li>Kultursommer</li>
        <li>Kulturwinter</li>
      </ul>
    </Modal>
    <Modal link="modal-lifestyle" title="Lifestyle">
      <h2>Unsere Empfehlungen</h2>
      <ul class="content-ul">
        <li>Ida</li>
        <li>Kostprobe</li>
        <li>Lebensstil</li>
      </ul>
    </Modal>
    <Modal link="modal-mobilitaet" title="Moblität">
      <h2>Unsere Empfehlungen</h2>
      <ul class="content-ul">
        <li>Mobilitätsjournal</li>
        <li>Innovationsmotor Mobilitätsindustrie</li>
        <li>Themenmagazin Mobilität</li>
      </ul>
    </Modal>
    <Modal link="modal-tourismus-reisen" title="Tourismus &amp; Reisen">
      <h2>Unsere Empfehlungen</h2>
      <ul class="content-ul">
        <li>Reisezeit</li>
        <li>Lust auf Kärnten</li>
        <li>Erlebnisreich</li>
      </ul>
    </Modal>
    <Modal link="modal-wirtschaft" title="Wirtschaft und Spezialthemen ">
      <h2>Unsere Empfehlungen</h2>
      <ul class="content-ul">
        <li>Primus</li>
        <li>Kärntner Vorzeigebetriebe</li>
        <li>Obersteiermark-Journal</li>
        <li>Im Fokus Weststeiermark</li>
        <li>Kärnten Regional</li>
        <li>Stars of Styria</li>
      </ul>
    </Modal>

    <div class="row justify-content-center hinweis-box  margin-t-s margin-b-m ">
      <div class="col-lg-2 bg-left align-self-center"><span class="material-icons">lightbulb</span></div>
      <div class="col-lg-10 bg-right align-self-center">
        <p>
          Individuelle Werbelösungen: Der Mix macht‘s! Je nach Werbeziel empfehlen wir eine Kombination aus Print und Digital, um Ihre Zielgruppe crossmedial zu erreichen und Streuverluste zu minimieren. Die Tarife richten sich dabei nach den allgemein gültigen Tarifen für <router-link :to="{ name: 'Basisplatzierung' }" class="content-link">Basisplatzierung</router-link> bzw. für die jeweiligen
          <router-link :to="{ name: 'DisplayWerbung' }" class="content-link">digitalen Werbeformen.</router-link>
        </p>
      </div>
    </div>

    <div class="margin-tb-m"></div>

    <!--<div class="row justify-content-center align-self-center bg-blue-light highlight-box margin-t-m margin-b-m">
      <div class="col-sm-6 d-flex align-self-center">
        <p>Was Interessantes dabei?</p>
      </div>
      <div class="col-sm-6 d-flex align-self-center justify-content-end">
        <div class="btn-std btn-bg-blue-dark">
          <router-link to="kontakt-formular">Jetzt anfragen</router-link>
        </div>
      </div>
    </div>-->

    <h2>Lassen Sie sich inspirieren</h2>
    <p>Werfen Sie einen Blick in unsere Erfolgsbeispiele!</p>
    <div class="row umsetzungs-bsp justify-content-center">
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/branchenloesungen/Kostprobe.pdf" target="_blank">
          <img src="@/assets/img/img-content/branchenloesungen/bsp-kostprobe.jpg" alt="Kostprobe" class="img-fluid shadow" />
        </a>
      </div>

      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/branchenloesungen/Kompass-Lehre-2021.pdf" target="_blank">
          <img src="@/assets/img/img-content/branchenloesungen/bsp-kompass-lehre.jpg" alt="Kompass Lehre" class="img-fluid shadow" />
        </a>
      </div>
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/branchenloesungen/Kleine-Zeitung-Themenmagazin-Gesundheit-2022.pdf" target="_blank">
          <img src="@/assets/img/img-content/branchenloesungen/bsp-gesundheit-v2.jpg" alt="Gesundheit" class="img-fluid shadow" />
        </a>
      </div>
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/branchenloesungen/Themenmagazin-Moblitaet.pdf" target="_blank">
          <img src="@/assets/img/img-content/branchenloesungen/bsp-moblitaet.jpg" alt="Moblität" class="img-fluid shadow" />
        </a>
      </div>

      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/branchenloesungen/Kultursommer-Kaernten.pdf" target="_blank">
          <img src="@/assets/img/img-content/branchenloesungen/bsp-kultursommer.jpg" alt="Kultursommer" class="img-fluid shadow" />
        </a>
      </div>
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/branchenloesungen/Reisemantel_November-2021_STMK.pdf" target="_blank">
          <img src="@/assets/img/img-content/branchenloesungen/bsp-reisezeit.jpg" alt="Reisezeit" class="img-fluid shadow" />
        </a>
      </div>
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/branchenloesungen/Primus-Kaernten.pdf" target="_blank">
          <img src="@/assets/img/img-content/branchenloesungen/bsp-primus-ktn.jpg" alt="Primus Kärnten" class="img-fluid shadow" />
        </a>
      </div>
      <div class="col-lg-4 col-md-6">
        <a href="../../pdf/branchenloesungen/Bauen-und-Wohnen.pdf" target="_blank">
          <img src="@/assets/img/img-content/branchenloesungen/bsp-bauen-wohnen.jpg" alt="Bauen &amp; Wohnen" class="img-fluid shadow" />
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ThemenschwerpunktKalenderComponent from '@/components/ThemenschwerpunktKalenderComponent.vue';
import ModalButton from '@/components/util/ModalButton.vue';
import Modal from '@/components/util/Modal.vue';
import Slider from '@/components/util/Slider.vue';
import SliderItem from '@/components/util/SliderItem.vue';

export default defineComponent({
  components: {
    ThemenschwerpunktKalenderComponent,
    ModalButton,
    Modal,
    Slider,
    SliderItem,
  },
});
</script>
