
import { computed, defineComponent, onMounted, ref, watch } from 'vue';

export default defineComponent({
  props: {
    branche: { type: String, default: 'Alle Branchen' },
    monat: { type: String, default: 'Alle Monate' },
  },
  components: {},
  setup(props) {
    const niceurl = (text: string) =>
      text
        .toLowerCase()
        .replace('ä', 'ae')
        .replace('ü', 'ue')
        .replace('ö', 'oe')
        .replace(/[^a-z0-9]+/g, '-')
        .replace(/-\s*$/, '')
        .replace(/^-\s*/, '');

    const activeMonat = ref(niceurl(props.monat));
    const activeBranche = ref(niceurl(props.branche));

    onMounted(() => {
      if (document.location.hash) {
        const split = document.location.hash.slice(1).split('/');
        activeBranche.value = split[0];
        activeMonat.value = split[1];
      }
    });

    watch([activeMonat, activeBranche], () => {
      document.location.hash = `#${activeBranche.value}/${activeMonat.value}`;
    });

    const branchen = ['Alle Branchen', 'Bauen & Wohnen', 'Bildung & Karriere', 'Gesundheit', 'Kulinarik', 'Kultur', 'Lifestyle', 'Mobilität', 'Spezialthema', 'Tourismus & Reise', 'Wirtschaft'];
    const monate = ['Alle Monate', 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

    const datenSchwerpunkte = [
      {
        Monat: 'Februar',
        Branche: 'Gesundheit',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Lebenslust-2022.pdf" target="_blank">Lebenslust <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '12.02.2022',
        Anzeigenschluss: '02.02.2022',
        Druckunterlagenschluss: '02.02.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Februar',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung_Wohnen-Journal_2022.pdf" target="_blank">Wohnen <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '25.02.2022',
        Anzeigenschluss: '18.02.2022',
        Druckunterlagenschluss: '23.02.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Februar',
        Branche: 'Bildung & Karriere',
        Themenschwerpunkt: 'Lehrlinge',
        Erscheinungstermin: '26.02.2022',
        Anzeigenschluss: '16.02.2022',
        Druckunterlagenschluss: '21.02.2022',
        Typ: 'Mitdruck',
      },
      { Monat: 'Februar', Branche: 'Spezialthema', Themenschwerpunkt: 'Pausenzeitung', Erscheinungstermin: '28.02.2022', Anzeigenschluss: '27.12.2021', Druckunterlagenschluss: '10.01.2022', Typ: 'Journal' },
      {
        Monat: 'März',
        Branche: 'Lifestyle',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Lebensstil-2022.pdf" target="_blank">Lebensstil <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '02.03.2022',
        Anzeigenschluss: '18.02.2022',
        Druckunterlagenschluss: '25.02.2022',
        Typ: 'Druckstrecke',
      },
      {
        Monat: 'März',
        Branche: 'Mobilität',
        Themenschwerpunkt: 'motionexpo 2022',
        Erscheinungstermin: '04.03.2022',
        Anzeigenschluss: '18.02.2022',
        Druckunterlagenschluss: '25.02.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'März',
        Branche: 'Tourismus & Reise',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Reisezeit-2022.pdf?=020602022" target="_blank">Reisezeit <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '05.03.2022',
        Anzeigenschluss: '23.02.2022',
        Druckunterlagenschluss: '23.02.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'März',
        Branche: 'Gesundheit',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Lebenslust-2022.pdf" target="_blank">Lebenslust <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '06.03-2022',
        Anzeigenschluss: '23.02.2022',
        Druckunterlagenschluss: '01.03.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'März',
        Branche: 'Bildung & Karriere',
        Themenschwerpunkt: 'FH Guide',
        Erscheinungstermin: '09.03.2022',
        Anzeigenschluss: '25..02.2022',
        Druckunterlagenschluss: '04.03.2022',
        Typ: 'Druckstrecke',
      },
      {
        Monat: 'März',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: 'Bauratgeber',
        Erscheinungstermin: '16.03.2022',
        Anzeigenschluss: '18.02.2022',
        Druckunterlagenschluss: '23.02.2022',
        Typ: 'Ratgeber',
      },
      {
        Monat: 'März',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Preisspiegel-Steiermark.pdf" target="_blank">Preisspiegel Serie <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '18.03.2022',
        Anzeigenschluss: '07.03.2022',
        Druckunterlagenschluss: '16.03.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'März',
        Branche: 'Gesundheit',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Pflege-2022.pdf" target="_blank">Pflege <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '20.03.2022',
        Anzeigenschluss: '09.03.2022',
        Druckunterlagenschluss: '14.03.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'März',
        Branche: 'Bildung & Karriere',
        Themenschwerpunkt: 'Recruiting Day',
        Erscheinungstermin: '23.03.2022',
        Anzeigenschluss: '11.03.2022',
        Druckunterlagenschluss: '16.03.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'März',
        Branche: 'Spezialthema',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Obersteiermark-Mantel-2022.pdf" target="_blank"> Mantel Obersteiermark <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '24.03.2022',
        Anzeigenschluss: '03.03.2022',
        Druckunterlagenschluss: '10.03.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'März',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung_Wohnen-Journal_2022.pdf" target="_blank">Wohnen <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '25.03.2022',
        Anzeigenschluss: '18.03.2022',
        Druckunterlagenschluss: '23.03.2022',
        Typ: 'Journal',
      },
      { Monat: 'März', Branche: 'Wirtschaft', Themenschwerpunkt: 'Primus', Erscheinungstermin: '26.03.2022', Anzeigenschluss: '16.03.2022', Druckunterlagenschluss: '16.03.2022', Typ: 'Journal' },
      { Monat: 'März', Branche: 'Spezialthema', Themenschwerpunkt: 'Frühlings- . Sommerbeilage Kinderzeitung', Erscheinungstermin: '26.03.2022', Anzeigenschluss: '14.02.2022', Druckunterlagenschluss: '21.02.2022', Typ: 'Journal' },
      {
        Monat: 'März',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: 'Wohnbauförderungsjournal',
        Erscheinungstermin: '27.03.2022',
        Anzeigenschluss: '01.03.2022',
        Druckunterlagenschluss: '11.03.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'März',
        Branche: 'Wirtschaft',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Vorzeigebetriebe-Kaernten-2022.pdf?=09062022" target="_blank">Kärntner Vorzeigebetriebe <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '29.03.2022',
        Anzeigenschluss: '16.03.2022',
        Druckunterlagenschluss: '16.03.2022',
        Typ: 'Mitdruck',
      },
      { Monat: 'April', Branche: 'Spezialthema', Themenschwerpunkt: 'Pausenzeitung', Erscheinungstermin: '04.04.2022', Anzeigenschluss: '31.01.2022', Druckunterlagenschluss: '14.02.2022', Typ: 'Journal' },
      {
        Monat: 'April',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: 'Magazin Leben im Garten',
        Erscheinungstermin: '06.04.2022',
        Anzeigenschluss: '18.03.2022',
        Druckunterlagenschluss: '25.03.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'April',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Preisspiegel-Steiermark.pdf" target="_blank">Preisspiegel Serie <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '08.04.2022',
        Anzeigenschluss: '28.03.2022',
        Druckunterlagenschluss: '06.04.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'April',
        Branche: 'Gesundheit',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Lebenslust-2022.pdf" target="_blank">Lebenslust <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '10.04.2022',
        Anzeigenschluss: '30.03.2022',
        Druckunterlagenschluss: '05.04.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'April',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-ProHolz-2022.pdf" target="_blank">pro:Holz <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '17.04.2022',
        Anzeigenschluss: '21.03.2022',
        Druckunterlagenschluss: '28.03.2022',
        Typ: 'Magazin LWC',
      },
      {
        Monat: 'April',
        Branche: 'Lifestyle',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Lebensstil-Magazin-2022.pdf" target="_blank">Magazin Lebensstil <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '20.04.2022',
        Anzeigenschluss: '01.04.2022',
        Druckunterlagenschluss: '04.04.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'April',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Preisspiegel-Steiermark.pdf" target="_blank">Preisspiegel Serie <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '22.04.2022',
        Anzeigenschluss: '11.04.2022',
        Druckunterlagenschluss: '20.04.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'April',
        Branche: 'Spezialthema',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Im-Fokus-Weststeiermark.pdf" target="_blank">Im Fokus Weststeiermark <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '24.04.2022',
        Anzeigenschluss: '12.04.2022',
        Druckunterlagenschluss: '19.04.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'April',
        Branche: 'Tourismus & Reise',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Lust-auf-Kaernten-Osttirol-2022.pdf" target="_blank">Lust auf Kärnten &amp; Osttirol<i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '26.04.2022',
        Anzeigenschluss: '15.04.2022',
        Druckunterlagenschluss: '22.04.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'April',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung_Wohnen-Journal_2022.pdf" target="_blank">Wohnen <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '29.04.2022',
        Anzeigenschluss: '22.04.2022',
        Druckunterlagenschluss: '27.04.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Mai',
        Branche: 'Gesundheit',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Lebenslust-2022.pdf" target="_blank">Lebenslust <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '08.05.2022',
        Anzeigenschluss: '27.04.2022',
        Druckunterlagenschluss: '03.05.2022',
        Typ: 'Journal',
      },
      { Monat: 'Mai', Branche: 'Spezialthema', Themenschwerpunkt: 'Pausenzeitung', Erscheinungstermin: '09.05.2022', Anzeigenschluss: '07.03.2022', Druckunterlagenschluss: '21.03.2022', Typ: 'Journal' },
      {
        Monat: 'Mai',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Preisspiegel-Steiermark.pdf" target="_blank">Preisspiegel Serie <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '13.05.2022',
        Anzeigenschluss: '02.05.2022',
        Druckunterlagenschluss: '11.05.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Mai',
        Branche: 'Kultur',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Kultursommer-2022.pdf" target="_blank">Kultursommer <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '15.05.2022',
        Anzeigenschluss: '19.04.2022',
        Druckunterlagenschluss: '25.04.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'Mai',
        Branche: 'Bildung & Karriere',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Fachkraefte-2022-Mutationen.pdf" target="_blank">Fachkräfte <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '21.05.2022',
        Anzeigenschluss: '06.05.2022',
        Druckunterlagenschluss: '13.05.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Mai',
        Branche: 'Wirtschaft',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Vorzeigebetriebe-Kaernten-2022.pdf?=09062022" target="_blank">Kärntner Vorzeigebetriebe <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '24.05.2022',
        Anzeigenschluss: '11.05.2022',
        Druckunterlagenschluss: '11.05.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Mai',
        Branche: 'Kulinarik',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Kulinarische-Feste-in-Kaernten-2022.pdf" target="_blank">Kulinarische Feste in Kärnten <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '25.05.2022',
        Anzeigenschluss: '13.05.2022',
        Druckunterlagenschluss: '18.05.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Mai',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Wohnen-Journal-2022.pdf" target="_blank">Wohnen <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '27.05.2022',
        Anzeigenschluss: '20.05.2022',
        Druckunterlagenschluss: '25.05.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Mai',
        Branche: 'Wirtschaft',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Primus.pdf" target="_blank">Primus <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '28.05.2022',
        Anzeigenschluss: '18.05.2022',
        Druckunterlagenschluss: '18.05.2022',
        Typ: 'Journal',
      },

      {
        Monat: 'Juni',
        Branche: 'Lifestyle',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Lebensstil-2022.pdf" target="_blank">Lebensstil <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '01.06.2022',
        Anzeigenschluss: '20.05.2022',
        Druckunterlagenschluss: '27.05.2022',
        Typ: 'Druckstrecke',
      },
      {
        Monat: 'Juni',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Preisspiegel-Steiermark.pdf" target="_blank">Preisspiegel Serie <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '03.06.2022',
        Anzeigenschluss: '23.05.2022',
        Druckunterlagenschluss: '01.06.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Juni',
        Branche: 'Tourismus & Reise',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Reisezeit-2022.pdf" target="_blank">Reisezeit <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '05.06.2022',
        Anzeigenschluss: '24.05.2022',
        Druckunterlagenschluss: '24.05.2022',
        Typ: 'Mantel',
      },
      { Monat: 'Juni', Branche: 'Spezialthema', Themenschwerpunkt: 'Pausenzeitung', Erscheinungstermin: '07.06.2022', Anzeigenschluss: '28.04.2022', Druckunterlagenschluss: '11.05.2022', Typ: 'Journal' },
      {
        Monat: 'Juni',
        Branche: 'Gesundheit',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Lebenslust-2022.pdf" target="_blank">Lebenslust <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '12.06.2022',
        Anzeigenschluss: '01.06.2022',
        Druckunterlagenschluss: '07.06.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Juni',
        Branche: 'Wirtschaft',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Vorzeigebetriebe-Steiermark-2022.pdf?=20052022" target="_blank">Steirische Vorzeigebetriebe <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '14.06.2022',
        Anzeigenschluss: '31.05.2022',
        Druckunterlagenschluss: '31.05.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Juni',
        Branche: 'Spezialthema',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Obersteiermark-Mantel-2022.pdf" target="_blank"> Mantel Obersteiermark <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '23.06.2022',
        Anzeigenschluss: '02.06.2022',
        Druckunterlagenschluss: '09.06.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'Juni',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung_Wohnen-Journal_2022.pdf" target="_blank">Wohnen <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '24.06.2022',
        Anzeigenschluss: '17.06.2022',
        Druckunterlagenschluss: '22.06.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Juni',
        Branche: 'Wirtschaft',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Weststeirische-Wirtschaft.pdf" target="_blank">Weststeirische Wirtschaft <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '26.06.2022',
        Anzeigenschluss: '09.06.2022',
        Druckunterlagenschluss: '14.06.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Juni',
        Branche: 'Wirtschaft',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Oststeirische-Wirtschaft.pdf" target="_blank">Oststeirische Wirtschaft <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '26.06.2022',
        Anzeigenschluss: '09.06.2022',
        Druckunterlagenschluss: '14.06.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Juni',
        Branche: 'Wirtschaft',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Vorzeigebetriebe-Kaernten-2022.pdf?=09062022" target="_blank">Kärntner Vorzeigebetriebe <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '28.06.2022',
        Anzeigenschluss: '17.06.2022',
        Druckunterlagenschluss: '17.06.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Juni',
        Branche: 'Tourismus & Reise',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Spuer-den-Sommer-2022.pdf" target="_blank">Spür den Sommer <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '29.06.2022',
        Anzeigenschluss: '15.06.2022',
        Druckunterlagenschluss: '22.06.2022',
        Typ: 'Journal',
      },

      {
        Monat: 'Juli',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Preisspiegel-Steiermark.pdf" target="_blank">Preisspiegel Serie <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '01.07.2022',
        Anzeigenschluss: '20.06.2022',
        Druckunterlagenschluss: '29.06.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Juli',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung_Wohnen-Journal_2022.pdf" target="_blank">Wohnen <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '29.07.2022',
        Anzeigenschluss: '22.07.2022',
        Druckunterlagenschluss: '27.07.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'August',
        Branche: 'Tourismus & Reise',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Lust-auf-Kaernten-Osttirol-2022.pdf" target="_blank">Lust auf Kärnten &amp; Osttirol<i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '17.08.2022',
        Anzeigenschluss: '05.08.2022',
        Druckunterlagenschluss: '12.08.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'August',
        Branche: 'Wirtschaft',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Vorzeigebetriebe-Kaernten-2022.pdf?=09062022" target="_blank">Kärntner Vorzeigebetriebe <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '23.08.2022',
        Anzeigenschluss: '10.08.2022',
        Druckunterlagenschluss: '10.08.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'August',
        Branche: 'Lifestyle',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Lebensstil-2022.pdf" target="_blank">Lebensstil <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '24.08.2022',
        Anzeigenschluss: '12.08.2022',
        Druckunterlagenschluss: '19.08.2022',
        Typ: 'Druckstrecke',
      },
      {
        Monat: 'August',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung_Wohnen-Journal_2022.pdf" target="_blank">Wohnen <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '26.08.2022',
        Anzeigenschluss: '19.08.2022',
        Druckunterlagenschluss: '24.08.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'September',
        Branche: 'Tourismus & Reise',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Reisezeit-2022.pdf?=02062022" target="_blank">Reisezeit <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '03.09.2022',
        Anzeigenschluss: '24.08.2022',
        Druckunterlagenschluss: '24.08.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'September',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Preisspiegel-Steiermark.pdf" target="_blank">Preisspiegel Serie <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '09.09.2022',
        Anzeigenschluss: '29.08.2022',
        Druckunterlagenschluss: '07.09.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'September',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Preisspiegel-Steiermark.pdf" target="_blank">Preisspiegel Serie <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '09.09.2022',
        Anzeigenschluss: '29.08.2022',
        Druckunterlagenschluss: '07.09.2022',
        Typ: 'Mitdruck',
      },
      { Monat: 'September', Branche: 'Spezialthema', Themenschwerpunkt: 'Herbst- . Winterbeilage Kinderzeitung', Erscheinungstermin: '10.09.2022', Anzeigenschluss: '01.08.2022', Druckunterlagenschluss: '08.08.2022', Typ: 'Journal' },
      {
        Monat: 'September',
        Branche: 'Gesundheit',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Lebenslust-2022.pdf" target="_blank">Lebenslust <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '11.09.2022',
        Anzeigenschluss: '31.08.2022',
        Druckunterlagenschluss: '06.09.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'September',
        Branche: 'Wirtschaft',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Vorzeigebetriebe-Steiermark-2022?=20052022.pdf" target="_blank">Steirische Vorzeigebetriebe <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '13.09.2022',
        Anzeigenschluss: '30.08.2022',
        Druckunterlagenschluss: '30.08.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'September',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Preisspiegel-Steiermark.pdf" target="_blank">Preisspiegel Serie <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '16.09.2022',
        Anzeigenschluss: '05.09.2022',
        Druckunterlagenschluss: '14.09.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'September',
        Branche: 'Spezialthema',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Obersteiermark-Mantel-2022.pdf" target="_blank"> Mantel Obersteiermark <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '22.09.2022',
        Anzeigenschluss: '01.09.2022',
        Druckunterlagenschluss: '08.09.2022',
        Typ: 'Mantel',
      },
      { Monat: 'September', Branche: 'Wirtschaft', Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Primus.pdf" target="_blank">Primus <i class="material-icons">chevron_right</i></a>', Erscheinungstermin: '24.09.2022', Anzeigenschluss: '14.09.2022', Druckunterlagenschluss: '14.09.2022', Typ: 'Journal' },
      {
        Monat: 'September',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung_Wohnen-Journal_2022.pdf" target="_blank">Wohnen <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '30.09.2022',
        Anzeigenschluss: '23.09.2022',
        Druckunterlagenschluss: '28.09.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Bildung & Karriere',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine_Zeitung_Wissen-was-geht_2022.pdf" target="_blank">Wissen. Was geht <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '01.10.2022',
        Anzeigenschluss: '13.09.2022',
        Druckunterlagenschluss: '16.09.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Kulinarik',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Kostprobe.pdf" target="_blank">Kostprobe <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '02.10.2022',
        Anzeigenschluss: '15.09.2022',
        Druckunterlagenschluss: '21.09.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Preisspiegel-Steiermark.pdf" target="_blank">Preisspiegel Serie <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '07.10.2022',
        Anzeigenschluss: '26.09.2022',
        Druckunterlagenschluss: '05.10.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Oktober',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Preisspiegel-Steiermark.pdf" target="_blank">Preisspiegel Serie <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '07.10.2022',
        Anzeigenschluss: '26.09.2022',
        Druckunterlagenschluss: '05.10.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Oktober',
        Branche: 'Gesundheit',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Lebenslust-2022.pdf" target="_blank">Lebenslust <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '09.10.2022',
        Anzeigenschluss: '28.09.2022',
        Druckunterlagenschluss: '04.10.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Mobilität',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Innovationsmotor-Mobilitaetsindustrie-OOEN-Kleine Zeitung-2022.pdf" target="_blank">Innovationsmotor Mobilitätsindustrie <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '12.10.2022',
        Anzeigenschluss: '07.09.2022',
        Druckunterlagenschluss: '14.09.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Bildung & Karriere',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Fachkraefte.pdf" target="_blank">Fachkräfte <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '15.10.2022',
        Anzeigenschluss: '30.09.2022',
        Druckunterlagenschluss: '07.10.2022',
        Typ: 'Journal',
      },

      {
        Monat: 'Oktober',
        Branche: 'Gesundheit',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Pflege-2022.pdf" target="_blank">Pflege <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '16.10.2022',
        Anzeigenschluss: '05.10.2022',
        Druckunterlagenschluss: '10.10.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Oktober',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Preisspiegel-Steiermark.pdf" target="_blank">Preisspiegel Serie <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '21.10.2022',
        Anzeigenschluss: '10.10.2022',
        Druckunterlagenschluss: '19.10.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Oktober',
        Branche: 'Bildung & Karriere',
        Themenschwerpunkt: 'Kompass Lehre',
        Erscheinungstermin: '22.10.2022',
        Anzeigenschluss: '30.09.2022',
        Druckunterlagenschluss: '23.09.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'Oktober',
        Branche: 'Gesundheit',
        Themenschwerpunkt: 'Ärzteführer',
        Erscheinungstermin: '25.10.2022',
        Anzeigenschluss: '23.09.2022',
        Druckunterlagenschluss: '07.10.2022',
        Typ: 'Ratgeber',
      },
      {
        Monat: 'Oktober',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung_Wohnen-Journal_2022.pdf" target="_blank">Wohnen <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '28.10.2022',
        Anzeigenschluss: '20.10.2022',
        Druckunterlagenschluss: '25.10.2022',
        Typ: 'Journal',
      },
      { Monat: 'Oktober', Branche: 'Wirtschaft', Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Primus.pdf" target="_blank">Primus <i class="material-icons">chevron_right</i></a>', Erscheinungstermin: '29.10.2022', Anzeigenschluss: '19.10.2022', Druckunterlagenschluss: '19.10.2022', Typ: 'Journal' },
      {
        Monat: 'Oktober',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-ProHolz-2022.pdf" target="_blank">pro:Holz <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '30.10.2022',
        Anzeigenschluss: '03.10.2022',
        Druckunterlagenschluss: '10.10.2022',
        Typ: 'Magazin LWC',
      },
      {
        Monat: 'November',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Preisspiegel-Steiermark.pdf" target="_blank">Preisspiegel Serie <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '04.11.2022',
        Anzeigenschluss: '24.10.2022',
        Druckunterlagenschluss: '02.11.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'November',
        Branche: 'Tourismus & Reise',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Reisezeit-2022.pdf?=02062022" target="_blank">Reisezeit <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '05.11.2022',
        Anzeigenschluss: '24.10.2022',
        Druckunterlagenschluss: '24.10.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'November',
        Branche: 'Gesundheit',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Lebenslust-2022.pdf" target="_blank">Lebenslust <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '06.11.2022',
        Anzeigenschluss: '25.10.2022',
        Druckunterlagenschluss: '31.10.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'November',
        Branche: 'Kultur',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Kulturwinter-2022.pdf" target="_blank">Kulturwinter <i class="material-icons">chevron_right</i></a></a>',
        Erscheinungstermin: '13.11.2022',
        Anzeigenschluss: '17.10.2022',
        Druckunterlagenschluss: '24.10.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'November',
        Branche: 'Wirtschaft',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Vorzeigebetriebe-Steiermark-2022.pdf?=20052022" target="_blank">Steirische Vorzeigebetriebe <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '15.11.2022',
        Anzeigenschluss: '31.10.2022',
        Druckunterlagenschluss: '31.10.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'November',
        Branche: 'Spezialthema',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Obersteiermark-Mantel-2022.pdf" target="_blank"> Mantel Obersteiermark <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '17.11.2022',
        Anzeigenschluss: '27.10.2022',
        Druckunterlagenschluss: '03.11.2022',
        Typ: 'Mantel',
      },
      {
        Monat: 'November',
        Branche: 'Gesundheit',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Themenmagazin-Gesundheit-2022.pdf?=02062022" target="_blank">Themenmagazin Gesundheit <i class="material-icons">chevron_right</i></a></a>',
        Erscheinungstermin: '19.11.2022',
        Anzeigenschluss: '28.10.2022',
        Druckunterlagenschluss: '04.11.2022',
        Typ: 'Magazin Zeitungspapier',
      },
      {
        Monat: 'November',
        Branche: 'Bauen & Wohnen',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung_Wohnen-Journal_2022.pdf" target="_blank">Wohnen <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '25.11.2022',
        Anzeigenschluss: '18.11.2022',
        Druckunterlagenschluss: '23.11.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'November',
        Branche: 'Bildung & Karriere',
        Themenschwerpunkt: 'Top-Jobs im Süden Österreichs',
        Erscheinungstermin: '26.11.2022',
        Anzeigenschluss: '11.11.2022',
        Druckunterlagenschluss: '18.11.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'November',
        Branche: 'Spezialthema',
        Themenschwerpunkt: 'Ida',
        Erscheinungstermin: '27.11.2022',
        Anzeigenschluss: '12.10.2022',
        Druckunterlagenschluss: '19.10.2022',
        Typ: 'Magazin LWC',
      },
      {
        Monat: 'November',
        Branche: 'Wirtschaft',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Vorzeigebetriebe-Kaernten-2022.pdf?=09062022" target="_blank">Kärntner Vorzeigebetriebe <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '29.11.2022',
        Anzeigenschluss: '16.11.2022',
        Druckunterlagenschluss: '16.11.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Dezember',
        Branche: 'Tourismus & Reise',
        Themenschwerpunkt: 'Erlebnisreich Winter',
        Erscheinungstermin: '01.12.2022',
        Anzeigenschluss: '24.11.2022',
        Druckunterlagenschluss: '24.11.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Dezember',
        Branche: 'Spezialthema',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Im-Fokus-Weststeiermark.pdf?=20052022" target="_blank">Im Fokus Weststeiermark <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '04.12.2022',
        Anzeigenschluss: '23.11.2022',
        Druckunterlagenschluss: '25.11.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Dezember',
        Branche: 'Tourismus & Reise',
        Themenschwerpunkt: 'Erlebnisreich Winter',
        Erscheinungstermin: '08.12.2022',
        Anzeigenschluss: '01.12.2022',
        Druckunterlagenschluss: '01.12.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Dezember',
        Branche: 'Bildung & Karriere',
        Themenschwerpunkt: 'Aus- und Weiterbildung',
        Erscheinungstermin: '10.12.2022',
        Anzeigenschluss: '25.11.2022',
        Druckunterlagenschluss: '02.12.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Dezember',
        Branche: 'Tourismus & Reise',
        Themenschwerpunkt: 'Erlebnisreich Winter',
        Erscheinungstermin: '15.12.2022',
        Anzeigenschluss: '07.12.2022',
        Druckunterlagenschluss: '07.12.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Dezember',
        Branche: 'Bildung & Karriere',
        Themenschwerpunkt: '<a href="../../pdf/themenschwerpunkte/Kleine-Zeitung-Stars-of-Styria-2022.pdf?=31052022" target="_blank">Stars of Styria <i class="material-icons">chevron_right</i></a>',
        Erscheinungstermin: '16.12.2022',
        Anzeigenschluss: '02.12.2022',
        Druckunterlagenschluss: '05.12.2022',
        Typ: 'Journal',
      },
      {
        Monat: 'Dezember',
        Branche: 'Tourismus & Reise',
        Themenschwerpunkt: 'Erlebnisreich Winter',
        Erscheinungstermin: '22.12.2022',
        Anzeigenschluss: '15.12.2022',
        Druckunterlagenschluss: '15.12.2022',
        Typ: 'Mitdruck',
      },
      {
        Monat: 'Dezember',
        Branche: 'Tourismus & Reise',
        Themenschwerpunkt: 'Erlebnisreich Winter',
        Erscheinungstermin: '29.12.2022',
        Anzeigenschluss: '22.12.2022',
        Druckunterlagenschluss: '22.12.2022',
        Typ: 'Mitdruck',
      },
    ];
    const FilterDatenSchwerpunkte = computed(() => {
      const branche = activeBranche.value;
      const monat = activeMonat.value;

      return datenSchwerpunkte.filter(schwerpunkt => {
        return (branche == 'alle-branchen' || niceurl(schwerpunkt.Branche) == branche) && (monat == 'alle-monate' || niceurl(schwerpunkt.Monat) == monat);
      });

      // && (activeBranche.value == 'Alle Einträge anzeigen' || schwerpunkt.Branche == activeBranche.value)
    });
    return {
      niceurl,
      branchen,
      activeBranche,
      datenSchwerpunkte,
      monate,
      activeMonat,
      FilterDatenSchwerpunkte,
    };
  },
});
