
import { defineComponent } from 'vue';
import ThemenschwerpunktKalenderComponent from '@/components/ThemenschwerpunktKalenderComponent.vue';
import ModalButton from '@/components/util/ModalButton.vue';
import Modal from '@/components/util/Modal.vue';
import Slider from '@/components/util/Slider.vue';
import SliderItem from '@/components/util/SliderItem.vue';

export default defineComponent({
  components: {
    ThemenschwerpunktKalenderComponent,
    ModalButton,
    Modal,
    Slider,
    SliderItem,
  },
});
